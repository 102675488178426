module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Halal Correct Certification","short_name":"Halal Correct","start_url":"/","description":"Halal Correct Certification provides certification of manufacturers and suppliers of halal products. With our experience of many years, Halal Correct Certification has a reputation for being a unique, professional,and expert organization in the Netherlands and Europe.","background_color":"#663399","display":"minimal-ui","icon":"src/images/logo2.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cc560e813001aa88df49cb04572413a7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
