import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import logo2 from "../images/logo2.png"

function Footer() {
  return (
    <div className="w-full">
      <div className="w-full max-w-7xl mx-auto px-8 pt-10">
        <section className="px-8 scr1000:px-20 py-10 border border-teal-500 rounded-lg grid grid-cols-1 scr600:grid-cols-2 scr1100:grid-cols-4 gap-6 relative before:border-4 before:border-teal-500 before:rounded-xl before:absolute before:inset-[-7px] before:z-[-1]">
          <div>
            <h3 className="pb-4 font-poppins font-semibold text-xl text-teal-500 uppercase">
              HALAL CORRECT CERTIFICATION
            </h3>
            <ul className="font-poppins font-light text-sm">
              <li>
                <span className="font-semibold uppercase">P.O: </span>
                179, 2300 AD Leiden
              </li>
              <li>
                <span className="font-semibold">ADDRESS: </span>
                Perzikweg 9B,2321 DG Leiden,The Netherlands
              </li>
              <li>
                <span className="font-semibold">Phone: </span>
                <a
                  href="tel:+31 71 523 57 70"
                  className="text-blue-500 hover:text-blue-700 transition duration-300"
                >
                  +31 71 523 57 70
                </a>
              </li>
              <li>
                <span className="font-semibold">Email: </span>
                <a
                  href="mailto:info@halalcorrect.com"
                  className="text-blue-500 hover:text-blue-700 transition duration-300"
                >
                  info@halalcorrect.com
                </a>
              </li>
              <li>
                <span className="font-semibold">SCOPE: </span>
                Halal inspection and Halal Certification
              </li>
            </ul>
            <div className="mt-3 flex gap-2 flex-wrap">
              <a
                href="https://twitter.com/CorrectHalal?fbclid=IwAR2b53dwdUFMs7ceU1D4C-2CCLJ4MeLU3oVVktobGD_UrhEUO3rIsJRB-sM"
                className="w-10 h-10 rounded-[50%] flex items-center justify-center bg-teal-500 hover:bg-teal-600 transition duration-300"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="text-white"
                  size="lg"
                />
              </a>
              <a
                href="https://www.facebook.com/HalalCorrect/"
                className="w-10 h-10 rounded-[50%] flex items-center justify-center bg-teal-500 hover:bg-teal-600 transition duration-300"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className="text-white"
                  size="lg"
                />
              </a>
              <a
                href="https://www.instagram.com/halalcorrectcertification/?fbclid=IwAR1U7smeVmtDIcihlAYncNIu51CquhDowjm699XGB6iXHn4plsjSJQuoUfc"
                className="w-10 h-10 rounded-[50%] flex items-center justify-center bg-teal-500 hover:bg-teal-600 transition duration-300"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="text-white"
                  size="lg"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/tqhcc-halalcorrectcertification/"
                className="w-10 h-10 rounded-[50%] flex items-center justify-center bg-teal-500 hover:bg-teal-600 transition duration-300"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  className="text-white"
                  size="lg"
                />
              </a>
            </div>
          </div>
          <div>
            <h3 className="pb-4 font-poppins font-semibold text-xl text-teal-500 uppercase">
              Useful Links
            </h3>
            <ul className=" font-poppins font-normal text-sm">
              {usefulLinks.map(element => (
                <li
                  key={element.text}
                  className="text-black hover:text-blue-500 transition duration-300"
                >
                  {"> "}
                  <Link to={element.path}>{element.text}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="pb-4 font-poppins font-semibold text-xl text-teal-500 uppercase">
              Contact us
            </h3>
            <ul className=" font-poppins font-normal text-sm">
              {contactLinks.map(element => (
                <li
                  key={element.text}
                  className="text-black hover:text-blue-500 transition duration-300"
                >
                  {"> "}
                  <Link to={element.path}>{element.text}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex items-center justify-center">
            <img
              src={logo2}
              alt="halal correct logo"
              className="w-full max-w-[300px]"
            />
          </div>
        </section>
        <p className="max-w-[600px] py-10 mx-auto font-poppins font-light text-lg text-teal-500 text-center">
          © Copyright{" "}
          <span className="font-semibold">Halal Correct Certification.</span>{" "}
          All Rights Reserved Powered by CODA-TECH
        </p>
      </div>
    </div>
  )
}

export default Footer

const usefulLinks = [
  {
    text: "Home",
    path: "/",
  },
  {
    text: "Who are we?",
    path: "/about/",
  },
  {
    text: "Company structure",
    path: "/about/structure",
  },
  {
    text: "Our services",
    path: "/certification/services",
  },
  {
    text: "Benefits of our certification",
    path: "/certification/benefits",
  },
  {
    text: "Meaning of halal",
    path: "/about/halal-meaning",
  },
]
const contactLinks = [
  {
    text: "Apply",
    path: "/contact/apply",
  },
  {
    text: "Request for information",
    path: "/contact/information",
  },
  {
    text: "Feedback",
    path: "/contact/feedback",
  },
  {
    text: "Complaint",
    path: "/contact/complaint",
  },
  {
    text: "Halal certificate check",
    path: "/contact/check",
  },
  {
    text: "Locations",
    path: "/about/locations",
  },
]
